import LocalisedStrings from "../Definitions/LocalisedStrings";
import useWeather from "../hooks/useWeather";

export default function Header() {
    const weather = useWeather();

    return (
        <header className="sticky top-0 z-10 p-4 bg-white">
            <div className="max-w-screen-xl mx-auto flex flex-row items-center justify-between text-black">
                <div className="w-full sm:w-1/3 flex items-center justify-start">
                    <img src="https://cesa.webtemplate.com.au/sites/3777/files/11226/logo2.webp" className="hidden lg:block h-16" alt="St Paul's College Logo" />
                    <img src="https://cesa.webtemplate.com.au/sites/3777/apple-touch-icon.png" className="block lg:hidden h-12 sm:h-16" alt="St Paul's College Logo" />
                </div>
                <div className="hidden lg:block text-center text-nowrap">
                    <h1 className="text-3xl font-black text-[#0081C6]">
                        {LocalisedStrings.GetString('title')}
                    </h1>
                </div>
                <div className="text-center w-full sm:w-1/3 flex flex-row gap-2 sm:gap-4 items-center justify-center sm:justify-end text-xs sm:text-sm font-bold text-black">
                    <div className="flex-1 sm:flex-initial text-nowrap hover:bg-accent-hover transition-colors py-4 px-6 rounded-3xl border-2 border-black">
                        <i className="bi bi-moon-stars-fill me-2" />
                        {new Date().toLocaleTimeString('en-AU', { hour: '2-digit', minute: '2-digit' }).toUpperCase()}
                    </div>
                    {weather && (
                        <div className="text-center flex-1 sm:flex-initial text-nowrap hover:bg-accent-hover transition-colors py-4 px-6 rounded-3xl bg-[#FFC425] border-2 border-[#FFC425]">
                            <i className="bi bi-sun-fill me-2" />
                            {Math.round(weather.main.temp)}°C
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
}