import 'reflect-metadata';
import { ParsingException } from './ParsingException';

class ParsingExtensions {
    public static GetCmsReferenceId(s: string): string {
        return s?.substring(s.indexOf(".") + 1);
    }
}

class CMSCachedReference {
    protected readonly _parent: any;
    protected readonly _field: any;
    private readonly _key: string;

    constructor(parent: any, field: any, key?: string) {
        this._parent = parent;
        this._field = field;
        this._key = key!;
    }

    public SetFromDict<T>(dict: Map<string, T>) {
        try {
            if (this._field != null) {
                this._parent[this._field] = dict.get(this._key);
            }
        }
        catch (err: any) {
            throw new Error(`Failed to set value from dict, field: ${this._field}, key: ${this._key}\n${err}"`);
        }
    }
}

class CMSCachedReferenceArray extends CMSCachedReference {
    private readonly _keys: string[];

    public constructor(parent: any, field: any, keys?: string[]) {
        super(parent, field);
        this._keys = keys!;
    }

    public SetFromDict<T>(dict: Map<string, T>) {
        const arr = new Array<T>(this._keys.length);
        let j = 0;

        for (let i = 0; i < arr.length; i++) {
            const key = this._keys?.[i];
            if (key) {
                const value = dict.get(key);
                if (!value) {
                    throw new Error(`Dict for type ${this._field} missing item ${key}`);
                } else {
                    arr[j++] = value;
                }
            } else {
                console.error(`Dict for type ${this._field} contains a null or empty key`);
            }
        }

        if (this._field != null) {
            this._parent[this._field] = arr;
        }
    }
}

export class CMSReferenceCache extends Map<any, CMSCachedReference[]> { }

enum CMSValidationEntryType {
    Error,
    Warning,
    Info
}

interface CMSValidationEntry {
    Type: CMSValidationEntryType;
    Message: string;
    Group: CMSDataGroup;
}

export class CMSValidationResult extends Array<CMSValidationEntry> {

}

export class Parsing {
    public static CrossReference<T>(dict: Map<string, T>, ctor: new () => T, referenceCache: CMSReferenceCache) {
        const referenceList = referenceCache.get(ctor);
        if (referenceList) {
            let i = 0;
            for (let j = referenceList.length; i < j; i++) {
                const reference = referenceList[i];
                reference.SetFromDict<T>(dict);
            }
        }
    }

    public static ValidateData<TDataGroup extends CMSDataGroup>(dict: Record<string, TDataGroup>, validationResults: CMSValidationResult) {
        for (const dataEntry of Object.values(dict)) {
            dataEntry.ValidateData(validationResults);
        }
    }

    public static ValidateDataDictionaries(obj: any, validationResults: CMSValidationResult) {
        const members = Object.getOwnPropertyNames(obj);
        for (const member of members) {
            const cmsClass = Reflect.getMetadata('class', obj[member].constructor);
            if (!cmsClass || !cmsClass?.Class) {
                return;
            }

            const attributesList = Reflect.getMetadataKeys(cmsClass.Class);
            for (let i = 0; i < attributesList.length; i++) {
                const a = attributesList[i];
                if (a == 'group') {
                    const objectDict = obj[member] as Map<string, typeof cmsClass.Class>;
                    this.ValidateData<typeof cmsClass.Class>(objectDict, validationResults);
                }
            }
        }
    }

    public static CrossReferenceAll(obj: any, referenceCache: CMSReferenceCache) {
        const members = Object.getOwnPropertyNames(obj);
        for (const member of members) {
            const cmsClass = Reflect.getMetadata('class', obj[member].constructor);
            if (!cmsClass || !cmsClass?.Class) {
                return;
            }

            const attributesList = Reflect.getMetadataKeys(cmsClass.Class);
            for (let i = 0; i < attributesList.length; i++) {
                const a = attributesList[i];
                if (a == 'group') {
                    const objectDict = obj[member] as Map<string, typeof cmsClass.Class>;
                    this.CrossReference<typeof cmsClass.Class>(objectDict, cmsClass.Class, referenceCache);
                }
            }
        }
    }

    public static ParseDictionariesParallel(obj: any, data: Record<string, object>, referenceCache: CMSReferenceCache) {
        const parseJobCaches: Array<CMSReferenceCache> = [];
        try {
            const members = Object.getOwnPropertyNames(obj);
            for (const member of members) {
                const cache = new CMSReferenceCache();
                parseJobCaches.push(cache);
                this.ParseCMSGroup(member, obj, data, cache);
            }
        }
        catch (err: any) {
            throw new Error(err);
        }
        this.MergeReferenceCaches(parseJobCaches, referenceCache);
    }

    private static MergeReferenceCaches(parseJobCaches: Array<CMSReferenceCache>, referenceCache: CMSReferenceCache) {
        for (const parseJobCache of parseJobCaches) {
            for (const key of parseJobCache.keys()) {
                const cachedReferences = referenceCache.get(key);
                if (!cachedReferences) {
                    const parseJobCacheReference = parseJobCache.get(key);
                    if (parseJobCacheReference) {
                        referenceCache.set(key, parseJobCacheReference);
                    }
                } else {
                    const parseJobCacheReference = parseJobCache.get(key);
                    if (parseJobCacheReference) {
                        for (const reference of parseJobCacheReference) {
                            cachedReferences.push(reference);
                        }
                    }
                }
            }
        }
    }

    private static ParseCMSGroup(member: string, obj: any, data: Record<string, object>, referenceCache: CMSReferenceCache) {
        this.ParseMember(member, obj, data, referenceCache);
    }

    private static ParseMember(member: string, obj: any, data: Record<string, object>, referenceCache: CMSReferenceCache) {
        const type = typeof obj[member];
        if (type === 'string') {
            return;
        }

        const cmsClass = Reflect.getMetadata('class', obj[member].constructor);
        if (!cmsClass || !cmsClass?.Class) {
            return;
        }

        const attributesList = Reflect.getMetadataKeys(cmsClass.Class);
        for (let i = 0; i < attributesList.length; i++) {
            if (attributesList[i] !== 'group') {
                continue;
            }

            const groupAttribute = Reflect.getMetadata('group', cmsClass.Class);
            const key = groupAttribute.Key;

            const cmsValue = data[key];
            if (!cmsValue) {
                if (groupAttribute.Optional) {
                    continue;
                }
                throw new Error(`Data does not contain: ${key}, and this group is not optional`);
            }

            const array = cmsValue as Array<object>;
            obj[member] = new obj[member].constructor;

            try {
                this.ParseListToDictionary<string, typeof cmsClass.Class>(obj[member], array, referenceCache, cmsClass.Class, "id");
            }
            catch (err: any) {
                this.ThrowParsingException(err, cmsClass.Class.name);
            }
        }
    }

    public static ThrowParsingException(e: Error, memberType: string) {
        throw new ParsingException(`Error occured parsing '${memberType}' type! Message: ${e.message}`);
    }

    public static ParseListToDictionary<TKey, TDataGroup extends CMSDataGroup>(dict: Map<TKey, TDataGroup>, list: Array<object>, referenceCache: CMSReferenceCache, ctor: new () => TDataGroup, keyId = "id") {
        let i = 0;
        for (let j = list.length; i < j; i++) {
            const e = new ctor();
            const objectId = this.Parse(e, list[i] as Record<string, object>, referenceCache, keyId);
            dict.set(objectId as TKey, e);
        }
    }

    public static Parse(def: any, data: Record<string, object>, referenceCache: CMSReferenceCache, fieldId: string) {
        let objectId = null;
        let target = def;
        let keys = Array<string>();

        // eslint-disable-next-line no-cond-assign
        while (target = target.__proto__) {
            const i = Reflect.getMetadata('keys', target, target.constructor.name)
            if (i) {
                keys = keys.concat(i)
            }
        }

        for (const key of keys) {
            let json_value: any
            let json_key: string
            if (Reflect.hasMetadata('mapping', def, key)) {
                json_key = Reflect.getMetadata('mapping', def, key)
            } else {
                json_key = key
            }

            json_value = data[json_key]

            try {
                let new_value: any
                const design_type = Reflect.getMetadata("design:type", def, key)
                if (design_type === undefined) {
                    throw new Error("Meta data not defined")
                }

                if (Reflect.hasMetadata('union', def, key)) {
                    const values = <Array<any>>(Reflect.getMetadata('union', def, key))
                    let is_valid = false
                    for (let i = 0; i < values.length && !is_valid; ++i) {
                        if (json_value === values[i]) {
                            is_valid = true
                        }
                    }
                    if (!is_valid) {
                        console.error(`Enum ${key} doesn't have ${json_value} listed`)
                        json_value = values[0]
                    }
                    if (json_value === undefined) {
                        json_value = values[0]
                    }
                }

                if (Reflect.hasMetadata('reference', def, key)) {
                    const array_meta = Reflect.hasMetadata('array', def, key) ? Reflect.getMetadata('array', def, key) : null;
                    if (array_meta) {
                        const keys = new Array<string>();
                        const list = json_value as Array<string>;

                        let j = 0;
                        for (let l = list.length; j < l; j++) {
                            const listObj = list[j];
                            keys.push(ParsingExtensions.GetCmsReferenceId(listObj));
                        }

                        let cachedRefsList2 = referenceCache.get(design_type);
                        if (!cachedRefsList2) {
                            cachedRefsList2 = new Array<CMSCachedReference>();
                            referenceCache.set(design_type, cachedRefsList2);
                        }

                        cachedRefsList2.push(new CMSCachedReferenceArray(def, key, keys));
                        continue;
                    }
                    try {
                        const dataFieldRef = json_value as string;
                        const sId = ParsingExtensions.GetCmsReferenceId(dataFieldRef);

                        let cachedRefsList = referenceCache.get(design_type);
                        if (!cachedRefsList) {
                            cachedRefsList = new Array<CMSCachedReference>();
                            referenceCache.set(design_type, cachedRefsList);
                        }

                        cachedRefsList.push(new CMSCachedReference(def, key, sId));
                    }
                    catch (err: any) {
                        console.error(`CMS Parsing Exception: Group: ${def.constructor.name}; Field: ${key}; Exception: ${err}`)
                    }
                    continue;
                } else {
                    if (json_value == undefined) {
                        if (design_type === Boolean || design_type === Number || design_type === String || design_type === Array || design_type === Object) {
                            json_value = design_type();
                        } else {
                            json_value = new design_type();
                        }
                    }

                    if (typeof json_value == 'object') {
                        if (Array.isArray(json_value)) {
                            const array_meta = Reflect.hasMetadata('array', def, key) ? Reflect.getMetadata('array', def, key) : null
                            if (array_meta && (json_value as object[]).length) {
                                const ret = new Array<any>()
                                for (let i = 0; i < (json_value as object[]).length; ++i) {
                                    const e = json_value[i]
                                    let new_e: any
                                    if (typeof e == 'object') {
                                        if (Array.isArray(e)) {
                                            throw new Error(`${def.constructor.name}.${key}: array type cannot be array`)
                                        }

                                        const item = new array_meta(e)
                                        this.Parse(item, e, referenceCache, "")
                                        new_e = item
                                    } else {
                                        new_e = array_meta(e)
                                    }
                                    const expected_type = typeof e
                                    const value_type = typeof new_e
                                    if (expected_type != value_type) {
                                        throw new Error(`${def.constructor.name}.${key} array element requires type '${expected_type}', got '${value_type}' instead`)
                                    }
                                    ret.push(new_e)
                                }
                                new_value = ret
                            } else {
                                new_value = json_value
                            }
                        } else if (design_type.name == 'Array') {
                            if (json_value != null) {
                                throw new Error(`${def.constructor.name}.${key}: expected array type, got '${typeof json_value}' instead`)
                            }
                        } else {
                            const item = new design_type(json_value);
                            this.Parse(item, json_value, referenceCache, "");
                            new_value = item;
                        }
                    } else if (design_type.name == 'Date') {
                        new_value = new Date(json_value);
                    } else if (design_type.name == 'MixedDataType') {
                        new_value = new MixedDataType(json_value);
                    }
                    else {
                        new_value = design_type(json_value);
                    }

                    if (design_type.name == 'Date') {
                        if (typeof new_value !== 'object' || typeof new_value.getTime !== 'function' || isNaN(new_value.getTime())) {
                            console.error(`Failed to parse date field, with value type: ${typeof json_value} and value ${json_value}`)
                        }
                    } else if (typeof new_value != 'object') {
                        const expected_type = typeof new_value
                        const value_type = typeof json_value

                        if (expected_type != value_type) {
                            console.error(`Field was type ${expected_type} but the CMS contains a type ${value_type}`)
                        }
                    }
                }

                (<any>def)[key] = new_value
            }
            catch (err: any) {
                console.error(`CMS Parsing Exception: DataGroup: ${def.constructor.name}; Field: ${key}; Value: ${json_value}; Exception: ${err.message}`);
            }

            if (json_key == fieldId) {
                objectId = json_value;
            }
        }

        return objectId;
    }
}

export class CMSDataCollection extends Object {
    constructor(json?: object) {
        super()
        if (json === undefined) {
            json = new Object();
        }
    }

    public static metadata(symbol: string | symbol, value: any) {
        const meta = Reflect.metadata(symbol, value)
        return (target: object, key: string) => {
            let keys: Array<string>;

            if (Reflect.hasMetadata('keys', target, target.constructor.name)) {
                keys = Reflect.getMetadata('keys', target, target.constructor.name);
            } else {
                keys = Array<string>();
                Reflect.defineMetadata('keys', keys, target, target.constructor.name);
            }

            if (!keys.length || keys[keys.length - 1] != key) {
                keys.push(key);
            }

            meta(target, key);
        }
    }
}

export const CMSArray = (type: any) => CMSDataCollection.metadata('array', type);
export const CMSClass = (dataClass: object): ClassDecorator => (target: any) => Reflect.defineMetadata('class', { Class: dataClass }, target);
export const CMSEnum = <T>(enumObj: { [key: string]: T }) => CMSDataCollection.metadata('union', Object.values(enumObj));
export const CMSField = (jsonKey: string) => CMSDataCollection.metadata('mapping', jsonKey);
export const CMSGroup = (groupId: string, optional = false): ClassDecorator => (target: any) => Reflect.defineMetadata('group', { Key: groupId, Optional: optional }, target);
export const CMSReference = CMSDataCollection.metadata('reference', true);

export class CMSDataGroup extends CMSDataCollection {
    @CMSField('id')
    public Id: string

    public ValidateData(_validationResults: CMSValidationResult): void {
        throw new Error('Method not implemented.');
    }

    public ParseCommaSeparatedString(input: string): string[] | null {
        return input ? input.split(',') : null;
    }
}

export class MixedDataType {
    public BoolValue?: boolean;
    public StringValue?: string;
    public IntValue?: number;
    public FloatValue?: number;

    constructor(BoolValue?: boolean);
    constructor(StringValue?: string);
    constructor(IntValue?: number);
    constructor(FloatValue?: number);

    constructor(value?: boolean | string | number) {
        if (typeof value === 'boolean') {
            this.BoolValue = value;
        } else if (typeof value === 'string') {
            this.StringValue = value;
        } else if (typeof value === 'number') {
            if (Number.isInteger(value)) {
                this.IntValue = value;
                this.FloatValue = value;
            } else {
                this.FloatValue = value;
                this.IntValue = Math.floor(value);
            }
        }
    }
}