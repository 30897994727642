import { useEffect, useState } from "react";

interface Weather {
    main: {
        temp: number;
    }
}

/**
 * Custom hook to fetch weather data.
 *
 * In the future, this functionality will be refactored to call the portal API to 
 * retrieve weather information. This change will ensure the API key is not exposed
 * to the frontend and the data is cached on the server.
 *
 * @returns {Weather | null} The current weather data or null if not yet fetched.
 */
export default function useWeather(): Weather | null {
    const [weather, setWeather] = useState<Weather | null>(null);

    const fetchWeather = async () => {
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=Adelaide&units=metric&appid=${import.meta.env.VITE_OPEN_WEATHER_API_KEY}`);
            const data = await response.json() as Weather;
            setWeather(data);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchWeather();
    }, []);

    return weather;
}