import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '../../ParsingUtils/ParsingUtils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.Alerts, false)
export class Alert extends CMSDataGroup {
    @CMSField('title')
    public Title: string

    @CMSField('message')
    public Message: string

    @CMSField('starts_at')
    public StartsAt: Date

    @CMSField('ends_at')
    public EndsAt: Date
}

@CMSClass(Alert)
export class AlertsData extends Map<string, Alert> { }