import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '../../ParsingUtils/ParsingUtils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.SettingsLogos, false)
export class SettingsLogos extends CMSDataGroup {
    @CMSField('large')
    public Large: string

    @CMSField('small')
    public Small: string
}

@CMSClass(SettingsLogos)
export class SettingsLogosData extends Map<string, SettingsLogos> { }