import { useEffect, useState } from "react";
import { PortalCMSData } from "../CMS/PortalCMSData";
import LocalisedStrings from "../Definitions/LocalisedStrings";

/**
 * Custom hook to fetch and manage CMS data.
 *
 * This hook fetches and parses cms content from the provided content URL.
 * 
 * @param {string} url The URL to fetch the CMS content from.
 * @param {boolean} useParserV2 Flag to determine whether to use the codegen content parser.
 *
 * @returns {PortalCMSData | null} The CMS data object.
 */
export default function useCMS(url: string, useParserV2: boolean): PortalCMSData | null {
    const [cmsData, setCmsData] = useState<PortalCMSData | null>(null);

    const fetchContent = async () => {
        try {
            const res = await fetch(url);
            const dict = await res.json() as Record<string, object>;
            const cmsData = new PortalCMSData(dict, useParserV2);
            setCmsData(cmsData);
            LocalisedStrings.Init(cmsData.LocalisedStrings);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchContent();
    }, [url, useParserV2]);

    return cmsData;
}