export default function Button(props: { text: string, href: string, image: string, newTab: boolean, disabled: boolean }) {
    return (
        <div className="flex flex-col items-center justify-center w-[20%] sm:w-[10%] lg:w-[10%] xl:w-[10%]">
            <a
                href={!props.disabled ? props.href : undefined}
                className='aspect-square bg-white border-2 border-white hover:border-black transition-colors w-full max-w-[100px] drop-shadow-md rounded-3xl mb-2 overflow-hidden'
                target={props.newTab ? '_blank' : '_self'}
                rel="noreferrer"
            >
                <img src={props.image} alt={props.text} className="rounded-3xl p-3 w-full h-full object-contain" />
            </a>
            <div className="text-white text-center text-xs sm:text-sm">
                {props.text}
            </div>
        </div>
    )
}