import { CMSClass, CMSDataGroup, CMSField, CMSGroup } from '../../ParsingUtils/ParsingUtils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.Settings, false)
export class Settings extends CMSDataGroup {
    @CMSField('value')
    public Value: boolean
}

@CMSClass(Settings)
export class SettingsData extends Map<string, Settings> { }