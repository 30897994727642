import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '../../ParsingUtils/ParsingUtils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.SettingsColors, false)
export class SettingsColors extends CMSDataGroup {
    @CMSField('value')
    public Value: string
}

@CMSClass(SettingsColors)
export class SettingsColorsData extends Map<string, SettingsColors> { }