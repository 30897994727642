import { useEffect, useState } from "react";

interface NewsEvent {
    id: number;
    title: string;
    date: string;
    url: string;
}

/**
 * Custom hook to fetch and manage news data.
 *
 * This hook retrieves a list of webtemplate news events from the portal API.
 *
 * @returns {NewsEvent[]} An array of news events, or an empty array if no data is available.
 */
export default function useNews(): NewsEvent[] {
    const [news, setNews] = useState<NewsEvent[]>([]);

    const fetchNews = async () => {
        try {
            const response = await fetch(`${import.meta.env.VITE_HOST}/api/news`);
            const data = await response.json() as NewsEvent[];
            setNews(data);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchNews();
    }, []);

    return news;
}