import { CMSGroup, CMSDataGroup, CMSField, CMSClass, CMSDataCollection, CMSEnum } from '../../ParsingUtils/ParsingUtils';
import CMSConstants from './CMSConstants';

export class BackgroundOverlay extends CMSDataCollection {
    @CMSField('enabled')
    public Enabled: boolean

    @CMSField('opacity')
    public Opacity: number
}

export enum BackgroundAnimationType {
    ZoomOut = 'zoom_out',
    ZoomIn = 'zoom_in'
}

export class BackgroundAnimated extends CMSDataCollection {
    @CMSField('enabled')
    public Enabled: boolean

    @CMSField('type')
    @CMSEnum(BackgroundAnimationType)
    public Type: BackgroundAnimationType

    @CMSField('duration')
    public Duration: number
}

export class BackgroundBlur extends CMSDataCollection {
    @CMSField('enabled')
    public Enabled: boolean

    @CMSField('amount')
    public Amount: number
}

@CMSGroup(CMSConstants.Groups.SettingsBackground, false)
export class SettingsBackground extends CMSDataGroup {
    @CMSField('image')
    public Image: string

    @CMSField('overlay')
    public Overlay: BackgroundOverlay

    @CMSField('animated')
    public Animated: BackgroundAnimated

    @CMSField('blur')
    public Blur: BackgroundBlur
}

@CMSClass(SettingsBackground)
export class SettingsBackgroundData extends Map<string, SettingsBackground> { }