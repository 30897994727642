export default class CMSConstants {
    public static Singleton = "singleton";

    public static Groups = class {
        public static Alerts = "alerts";
        public static Buttons = "buttons";
        public static LocalisedStrings = "localised_strings";
        public static Settings = "settings";
        public static SettingsBackground = "settings_background";
        public static SettingsButtons = "settings_buttons";
        public static SettingsColors = "settings_colors";
        public static SettingsLinks = "settings_links";
        public static SettingsLogos = "settings_logos";
        public static SettingsNews = "settings_news";
        public static SettingsWidgets = "settings_widgets";
    }
}