import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '../../ParsingUtils/ParsingUtils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.SettingsLinks, false)
export class SettingsLinks extends CMSDataGroup {
    @CMSField('value')
    public Value: string
}

@CMSClass(SettingsLinks)
export class SettingsLinksData extends Map<string, SettingsLinks> { }