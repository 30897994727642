import { CMSGroup, CMSDataGroup, CMSField, CMSEnum, CMSClass } from '../../ParsingUtils/ParsingUtils';
import CMSConstants from './CMSConstants';

enum ButtonType {
    Link = 'link',
}

@CMSGroup(CMSConstants.Groups.Buttons, false)
export class Button extends CMSDataGroup {
    @CMSField('text')
    public Text: string

    @CMSField('href')
    public Href: string

    @CMSField('image')
    public Image: string

    @CMSField('type')
    @CMSEnum(ButtonType)
    public Type: ButtonType

    @CMSField('disabled')
    public Disabled: boolean

    @CMSField('new_tab')
    public NewTab: boolean

    @CMSField('sort')
    public Sort: number
}

@CMSClass(Button)
export class ButtonsData extends Map<string, Button> { }