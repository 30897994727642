import { CMSGroup, CMSDataGroup, CMSField, CMSClass, CMSDataCollection } from "../../ParsingUtils/ParsingUtils"
import CMSConstants from "./CMSConstants"

export class Widget extends CMSDataCollection {
    @CMSField('enabled')
    public Enabled: boolean
}

export class WeatherWidget extends Widget {
    @CMSField('city')
    public City?: string
}

@CMSGroup(CMSConstants.Groups.SettingsWidgets, false)
export class SettingsWidgets extends CMSDataGroup {
    @CMSField('time')
    public Time: Widget

    @CMSField('date')
    public Date: Widget

    @CMSField('weather')
    public Weather: WeatherWidget
}

@CMSClass(SettingsWidgets)
export class SettingsWidgetsData extends Map<string, SettingsWidgets> { }